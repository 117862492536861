import React from 'react'
import Link from 'gatsby-link'
import PortfolioCard from '../components/PortfolioCard';
import Section from '../components/Section';
import Wave from '../components/Wave';
import staticdata from '../../staticdata.json'
import Cell from '../components/Cell';
import styled from 'styled-components'
import Navbar from '../components/Navbar/Navbar.js'
import Footer from '../components/footer'
import Testimonial from '../components/Testimonial'
import './services.css'
import Head from '../components/head'
import Card from '../components/Card';
import Sectionnew from '../components/Sectionnew';


const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`
const Button = styled.button`
    background: linear-gradient(102.24deg, #ffcc00 0%, #ffcc00 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: white;
    border: none;
    padding: 16px 60px;
    
    
    font-weight: 600;
    font-size: 18px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
    }
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Corporate = () => (
  <div>
      <Head title="Professional corporate catering London" />

<Navbar />
    <div className="Heroecom">
      <div className="HeroecomGroup">
        <h1>Corporate Catering Services</h1>
        <Wave />
      </div>
    </div>






    <div className="homenn">
    <p>A summer party to reward your employees for their hard work, a VIP client visiting and would like to offer them a special attention, the annual Christmas party and this year you do want to impress! <b>Choose Tima’s flavours</b> and we’ll take care of everything for you! All you have to do is relax and enjoy.

We offer bespoke or set menu services for corporate events! </p>
    </div>









 

   
    <Section
      image='/images/newbg.png'
      logo=''
      title="Interested?"
      text="Want Tima's Flavours to handle your professional catering services? Please provide as much details as you can on our contact form and we’ll get back to you within 48 hours"
      button="Get In Touch"
     />
      

      <div className="Cards">
      <h4>CUSTOMER STORIES</h4>
      <h2>5 STARS ON GOOGLE</h2></div>

<div className="Testimonial">
      
<Testimonial 
          title="We Hired Tima for our corporate event and the food was absolutely amazing as was the service! Highly recommended!"
          text=""
          author="Joshua"
          image='/images/josh.jpg' /> 
         
        <Testimonial 
          title="I absolutely recommend Tima to anyone who has an event that requires catering. I send my children to her cooking classes too!"
          text=""
          author="Elizabeth"
          image='/images/eli.jpg' />
             
    </div>
    <div className="Cardb">
    <Button>More Stories</Button>
</div>

     <Footer/>
    
 
     

  </div>
)

export default Corporate
